import React, { useEffect, useState } from "react"
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "./category.css";

const w = [{w: "w"}]

const CategoryDetial = (paths) => {
    const {path} = useParams();
    const [data, setData] = useState([]);
    const id = path;
      useEffect(() => {
            axios.get(`https://minggatu.zyberapi.site/post?category=${path}`)
            .then((response) => setData(response.data))
            .catch((e) => console.log(e));
      }, [path])
  return (

    <>
      <div className='content grid3 mtop s category'>
      {data.map((data, index) => {
          // const { cover, category, location, name, price, type } = val
          return (
            <Link to={`/news/${data._id}`}>
            <div className='box shadow sdaa category' key={index}>
              <div className='img-2'>
                <img src={data.photo} alt='' />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{ background: "#25b5791a", color: "#25b579", padding: 8 }}>
                    {data.name}</span>
                </div>
                {/* <h4>{data.name}</h4> */}
              </div>
              <div className='button flex'>
                <div>
                  <button className='btn2'>Дэлгэрэнгүй</button> <label htmlFor=''></label>
                </div>
                {/* <span>{type}</span> */}
              </div>
            </div>
            </Link>
          )
        })}
      </div>
      
    </>
  )
}

export default CategoryDetial
