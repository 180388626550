import React, { Component, useEffect, useState } from "react"
import { list } from "../data/Data"
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "./detial.css"


export const DetialNews = () => {
      const {id} = useParams();
      const [data, setData] = useState([]);

      useEffect(() => {
            axios.get(`https://minggatu.zyberapi.site/post/${id}`)
            .then((response) => setData(response.data))
            .catch((e) => console.log(e));
      }, [id]);
  //     state = {
  //       name: null,
  //       photo: null,
  //       description: null,
  //       description_1: null,
  //       description_2: null,
  //       description_3: null,
  //       description_4: null,
  //       description_5: null,
  //       teacher: null,
  //       error: null,
  //       success: null,
  //       delete: null,
  //       loading: false
  //     }
    
  //     goBack = () => {
  //       this.props.history.goBack();
  //     }
    
  //     componentDidMount = () => {
  //       this.setState({ loading: true });
  //       axios.get(
  //         'https://api.minggatu.com/api/v1/news/' + this.props.match.params.id
  //         )
  //         .then((result) => this.setState({ 
  //           ...result.data.data, 
  //           error: null, 
  //           loading: false 
  //         }))
  //         .catch((err) => this.setState({ 
  //           error: err.response.data.error.message, 
  //           loading: false 
  //         }));
  //     }
    
  //     handleType = (e) => {
  //       const {name, value} = e.target;
    
  //       this.setState({ [name]: value, error: null, success: null });
  //   }
  // render() { 
    return (

    
      <div className='detial-category'>
        <div className='detial'>
              <div className="garchig"><h1>{data.title}</h1></div>

              <div className='img_detial'>
                {/* <img src={this.state.photo} alt='' width="10rem" className="img-3"/> */}
                <img src={'https://minggatu.zyberapi.site/'+data.cover} alt="" className="img-3"/>
              </div>
              <div>
                <h2>{data.title}</h2>
                {/* <p className="p-content">{data.content}</p> */}
                <div className="content" dangerouslySetInnerHTML={{__html:data.content}} />
              </div>
            </div>
      </div>

    
  )
}