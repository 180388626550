import React, { useEffect, useState } from "react"
import { list } from "../../data/Data"
import axios from "axios";
import { Link } from "react-router-dom";

const RecentCard = () => {
      const [data, setData] = useState([]);
      const [category, setCategory] = useState([]);
      useEffect(() => {
            axios.get(`https://minggatu.zyberapi.site/post?sort=-createdAt&${category}`)
            .then((response) => {setData(response.data)})
            .catch((e) => console.log(e));
      }, [category])
  return (

    <>
      <div className='content grid3 mtop s'>
        <li>
          {/* <button value={'category=w'} onChange={(e) => setCategory(e.target.value)}>0</button> */}
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="">Бүгд</option>
            <option value="category=w">w</option>
            <option value="category=22">w2</option>
          </select>
        </li>
        {data.map((data, index) => {
          return (
                    <Link to={`/news/${data._id}`}>
          <div className='box shadow sdaa ' key={index}>
            <div className='img-2'>
            <img src={'https://minggatu.zyberapi.site/'+data.cover} alt=""/>
            </div>
            <div className='text'>
              <div className='category flex'>
                <span style={{ background: "#25b5791a", color: "#25b579" }}>
                  {data.category}</span>
              </div>
              <h4>{data.title}</h4>
            </div>
            <div className='button flex'>
              <div>
                <button className='btn2'>Дэлгэрэнгүй</button> <label htmlFor=''></label>
              </div>
              {/* <span>{type}</span> */}
            </div>
          </div>
          </Link>
          )
        })}
      </div>
      
    </>
  )
}

export default RecentCard

// <Link to={`/news/${data._id}`}>
// <div className='box shadow sdaa ' key={index}>
//   <div className='img-2'>
//     <img src={data.cover} alt='' />
//   </div>
//   <div className='text'>
//     {/* <div className='category flex'>
//       <span style={{ background: "#25b5791a", color: "#25b579" }}>
//         {data.category.name}</span>
//     </div> */}
//     <h4>{data.title}</h4>
//   </div>
//   <div className='button flex'>
//     <div>
//       <button className='btn2'>Дэлгэрэнгүй</button> <label htmlFor=''></label>
//     </div>
//     {/* <span>{type}</span> */}
//   </div>
// </div>
// </Link>